/**
 * *Rôle : Liste les vidéos depuis l'api Viméo
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ProgressiveImage from 'react-progressive-image';
import { Link } from 'gatsby';
import 'moment/locale/fr';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { video } from '../../redux/video/actionVideo';
import { pagingVideo } from '../../redux/pagingVideo/actionPagingVideo';
import H2 from '../functional/H2';
import PlaceHolder from '../functional/LoadingPlaceHolder/LoadingPlaceHolderVideos';
import PlaceHolderHomePage from '../functional/LoadingPlaceHolder/LoadingPlaceHolderVideoTemplateHomePage';

const ListLastVideos = (props) => {
	const { template } = props;

	// REDUX STORE
	const dispatch = useDispatch();
	const currentListeVideoInRedux = useSelector((state) => state.video);
	const pagingVideoInRedux = useSelector((state) => state.pagingVideo);

	const [listVideos, setListVideos] = useState([]);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasmore] = useState(false);
	const [currentPage, setCurrentpage] = useState(1);
	const [getMoreVideo, setGetMoreVideo] = useState(false);

	// Transmettre paging a redux pour tester si next page est atteint...
	useEffect(() => {
		// si les data existe dans redux
		if (currentListeVideoInRedux) {
			setListVideos(currentListeVideoInRedux.state);
		}
		if (!currentListeVideoInRedux || getMoreVideo) {
			setLoading(true);
			axios
				.get(
					`https://us-central1-dourousnet-3fcb0.cloudfunctions.net/getAllVideo?per_page=26&page=${currentPage}`
				)
				.then(function (res) {
					setGetMoreVideo(false);

					// setGetAllVideos(res.data)
					const newList = listVideos.concat(res.data.data);
					setListVideos(newList);

					// Stock tab & current paging in redux
					dispatch(video(newList));
					dispatch(pagingVideo(res.data.paging));

					setLoading(false);
					//test si il reste des éléments a charger
					if (res.data.paging.next === null) {
						setHasmore(true);
					}
				})
				.catch(function (err) {
					// console.log('error get Vimeo', err)
					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage, currentListeVideoInRedux]);

	const getMoreData = () => {
		if (pagingVideoInRedux && pagingVideoInRedux.state.next !== null) {
			setCurrentpage(currentPage + 1);
			setGetMoreVideo(true);
		}
	};

	let buttonLoadMore;

	if (!hasMore) {
		buttonLoadMore = (
			<button
				onClick={() => getMoreData()}
				className="mt-5 w-full text-secondary hover:text-primary uppercase animate font-semibold p-6"
			>
				charger plus ...
			</button>
		);
	} else {
		buttonLoadMore = (
			<div className="flex items-center justify-center mt-5 w-full text-gray-400 uppercase animate p-6 text-xxs">
				Il n'y a plus d'autre vidéos
			</div>
		);
	}

	const createUri = (defaultUri) => {
		const uri = defaultUri.replace('/videos/', '');
		return uri;
	};

	let listeVideo;
	if (!template && listVideos && listVideos.length > 0) {
		listeVideo = listVideos.map((video, index) =>
			!video.is_playable || video.privacy.embed !== 'public' ? null : (
				<Link to={`/watch-video/${createUri(video.uri)}`} key={index} className="mb-5">
					<div className="w-full mb-5 lg:mb-0">
						<div className="w-full relative">
							<div className="relative overflow-hidden rounded-t-xl rounded-b-md h-44 max-h-44 w-full bg-white">
								<ProgressiveImage
									delay={500}
									src={video.pictures.sizes[3].link}
									placeholder={video.pictures.sizes[2].link}
								>
									{(src, loading) => (
										<img
											className="absolute top-0 left-0 w-full h-full hover-img-scale animate"
											style={{
												filter: loading ? 'blur(5px)' : 'blur(0px)',
												objectFit: 'cover',
												objectPosition: 'center center'
											}}
											src={src}
											alt=""
										/>
									)}
								</ProgressiveImage>
							</div>

							<svg
								className="h-10 w-10 rounded-full p-3 bg-gray-100 absolute -bottom-4 left-5 text-secondary"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								strokeWidth="2"
								stroke="currentColor"
								fill="#6c62af"
								strokeLinecap="round"
								strokeLinejoin="round"
							>
								<polygon points="5 3 19 12 5 21 5 3" />
							</svg>
						</div>
					</div>

					<div className="text-gray-400 text-sm mt-5 flex items-center justify-start">
						<svg
							className="h-3 w-3 mr-2"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="none"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<rect x="3" y="4" width="18" height="18" rx="2" ry="2" />{' '}
							<line x1="16" y1="2" x2="16" y2="6" />{' '}
							<line x1="8" y1="2" x2="8" y2="6" />{' '}
							<line x1="3" y1="10" x2="21" y2="10" />
						</svg>
						{moment(video.created_time).format('LL')}
					</div>
					<div className="font-bold text-lg">{video.name}</div>
				</Link>
			)
		);
	}

	let listeIndex = 0;
	const thisIsPlayable = () => {
		listeIndex = listeIndex + 1;
	};

	// MODULE HOME PAGE
	if (template === 'homePage' && listVideos && listVideos.length > 0) {
		listeVideo = listVideos.map((video, index) =>
			listeIndex <= 4 && video.is_playable === true && video.privacy.embed === 'public' ? (
				<Link
					to={`/watch-video/${createUri(video.uri)}`}
					key={index}
					id={listeIndex}
					className={`${
						listeIndex === 0
							? 'md:col-span-6 col-span-12 md:row-span-2 row-span-1 h-full'
							: `md:col-span-3 col-span-12 row-span-1 ${
									listeIndex === 1 || listeIndex === 2 ? 'mb-4' : null
							  }`
					}`}
				>
					<div className="w-full h-full mb-5 lg:mb-0">
						<div className="w-full h-full relative">
							<div
								className={`relative overflow-hidden rounded-xl ${
									listeIndex === 0
										? 'md:h-full h-44'
										: `${
												listeIndex === 1 || listeIndex === 2
													? 'h-44'
													: 'md:h-full h-44'
										  }`
								} w-full bg-white`}
							>
								<ProgressiveImage
									delay={500}
									src={video.pictures.sizes[3].link}
									placeholder={video.pictures.sizes[2].link}
								>
									{(src, loading) => (
										<img
											className="absolute top-0 left-0 w-full h-full hover-img-scale animate"
											style={{
												filter: loading ? 'blur(5px)' : 'blur(0px)',
												objectFit: 'cover',
												objectPosition: 'center center'
											}}
											src={src}
											alt=""
										/>
									)}
								</ProgressiveImage>
								<div className="absolute bottom-0 left-0 bg-black hover:bg-white hover:bg-opacity-10 w-full h-full bg-opacity-40 animate"></div>
							</div>
							<div
								className={`absolute ${
									listeIndex === 1 || listeIndex === 2
										? 'md:bottom-1 bottom-5 left-0'
										: 'md:bottom-1 bottom-10 left-0'
								}`}
							>
								<div
									className={`text-gray-200 flex items-center justify-start ${
										listeIndex === 0 ? 'text-sm px-5' : 'text-xxs px-2'
									}`}
								>
									<svg
										className="h-3 w-3 mr-2"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										strokeWidth="2"
										stroke="currentColor"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<rect x="3" y="4" width="18" height="18" rx="2" ry="2" />{' '}
										<line x1="16" y1="2" x2="16" y2="6" />{' '}
										<line x1="8" y1="2" x2="8" y2="6" />{' '}
										<line x1="3" y1="10" x2="21" y2="10" />
									</svg>
									{moment(video.created_time, 'YYYYMMDD').fromNow()}
								</div>
								<div
									className={`text-white hover:text-gray-200 font-bold ${
										listeIndex === 0
											? 'md:text-xl md:px-5 md:pb-5 text-sm px-2 pb-2'
											: 'text-sm px-2 pb-2'
									} animate`}
								>
									{video.name}
								</div>
							</div>
						</div>
					</div>
					{thisIsPlayable()}
				</Link>
			) : null
		);
	}

	if (!template) {
		return (
			<>
				<div className="bg-gray-100 px-2 sm:px-5 p-10">
					<H2 className="mb-16">Toutes les vidéos</H2>

					<div className="grid grid-col-1 md:grid-cols-2 gap-5 mb-0">{listeVideo}</div>

					{loading ? <PlaceHolder /> : <></>}

					{buttonLoadMore}
				</div>
			</>
		);
	}
	// MODULE HOME PAGE
	else if (template === 'homePage') {
		return (
			<div className="w-full md:py-20 pt-20 pb-5">
				<H2 className="mb-16">Vidéos récentes</H2>

				<div className="grid md:grid-rows-2 grid-rows-5 grid-cols-12 gap-x-4 gap-y-0">
					{listeVideo}
				</div>

				{loading ? <PlaceHolderHomePage /> : <></>}
			</div>
		);
	}
};

export default ListLastVideos;
