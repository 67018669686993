/**
 * *Rôle : Loader before get Data Liste Video HomePage
 * Copyright: © 2021 Remote Monkey S.A.S.U http://remote-monkey.fr
 */
import React from 'react';
import '../../../styles/skeleton.scss';

const LoadingPlaceHolderVideosHomePage = () => {
	return (
		<>
			<div className="hidden md:grid grid-rows-2 grid-cols-12 gap-x-4 gap-y-0">
				<div className="relative animate-pulse col-span-6 row-span-2 rounded-xl w-full h-full bg-white overflow-hidden translate-3d-none-after card border border-gray-200">
					<div className="absolute bottom-5 left-5 w-full">
						<div className="skeleton-box h-3 w-2/6 block mt-0 mb-2"></div>
						<div className="skeleton-box h-3 w-4/6 block"></div>
					</div>

					<div className="z-10 h-full flex items-center justify-center">
						<svg
							className="h-10 w-10 rounded-full p-3 bg-gray-200 text-white"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<polygon points="5 3 19 12 5 21 5 3" />
						</svg>
					</div>
				</div>

				<div className="relative animate-pulse mb-5 col-span-3 row-span-1 rounded-xl w-full bg-white overflow-hidden translate-3d-none-after card border border-gray-200 p-5">
					<div className="z-10 h-20 flex items-center justify-center">
						<svg
							className="h-7 w-7 rounded-full p-2 bg-gray-200 text-white"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<polygon points="5 3 19 12 5 21 5 3" />
						</svg>
					</div>
					<div className="skeleton-box h-2 w-2/6 block mt-0 mb-2"></div>
					<div className="skeleton-box h-2 w-4/6 block"></div>
				</div>

				<div className="relative animate-pulse mb-5 col-span-3 row-span-1 rounded-xl w-full bg-white overflow-hidden translate-3d-none-after card border border-gray-200 p-5">
					<div className="z-10 h-20 flex items-center justify-center">
						<svg
							className="h-7 w-7 rounded-full p-2 bg-gray-200 text-white"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<polygon points="5 3 19 12 5 21 5 3" />
						</svg>
					</div>
					<div className="skeleton-box h-2 w-2/6 block mt-0 mb-2"></div>
					<div className="skeleton-box h-2 w-4/6 block"></div>
				</div>

				<div className="relative animate-pulse col-span-3 row-span-1 rounded-xl w-full bg-white overflow-hidden translate-3d-none-after card border border-gray-200 p-5">
					<div className="z-10 h-20 flex items-center justify-center">
						<svg
							className="h-7 w-7 rounded-full p-2 bg-gray-200 text-white"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<polygon points="5 3 19 12 5 21 5 3" />
						</svg>
					</div>
					<div className="skeleton-box h-2 w-2/6 block mt-0 mb-2"></div>
					<div className="skeleton-box h-2 w-4/6 block"></div>
				</div>

				<div className="relative animate-pulse col-span-3 row-span-1 rounded-xl w-full bg-white overflow-hidden translate-3d-none-after card border border-gray-200 p-5">
					<div className="z-10 h-20 flex items-center justify-center">
						<svg
							className="h-7 w-7 rounded-full p-2 bg-gray-200 text-white"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<polygon points="5 3 19 12 5 21 5 3" />
						</svg>
					</div>
					<div className="skeleton-box h-2 w-2/6 block mt-0 mb-2"></div>
					<div className="skeleton-box h-2 w-4/6 block"></div>
				</div>
			</div>

			{/* Loading Place Holder responsive */}
			<div className="md:hidden">
				<div className="relative mb-5 animate-pulse col-span-3 row-span-1 rounded-xl w-full bg-white overflow-hidden translate-3d-none-after card border border-gray-200 p-5">
					<div className="z-10 h-28 flex items-center justify-center">
						<svg
							className="h-7 w-7 rounded-full p-2 bg-gray-200 text-white"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<polygon points="5 3 19 12 5 21 5 3" />
						</svg>
					</div>
					<div className="skeleton-box h-2 w-2/6 block mt-0 mb-2"></div>
					<div className="skeleton-box h-2 w-4/6 block"></div>
				</div>
				<div className="relative mb-5 animate-pulse col-span-3 row-span-1 rounded-xl w-full bg-white overflow-hidden translate-3d-none-after card border border-gray-200 p-5">
					<div className="z-10 h-28 flex items-center justify-center">
						<svg
							className="h-7 w-7 rounded-full p-2 bg-gray-200 text-white"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<polygon points="5 3 19 12 5 21 5 3" />
						</svg>
					</div>
					<div className="skeleton-box h-2 w-2/6 block mt-0 mb-2"></div>
					<div className="skeleton-box h-2 w-4/6 block"></div>
				</div>
				<div className="relative animate-pulse col-span-3 row-span-1 rounded-xl w-full bg-white overflow-hidden translate-3d-none-after card border border-gray-200 p-5">
					<div className="z-10 h-28 flex items-center justify-center">
						<svg
							className="h-7 w-7 rounded-full p-2 bg-gray-200 text-white"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							strokeWidth="2"
							stroke="currentColor"
							fill="#ffffff"
							strokeLinecap="round"
							strokeLinejoin="round"
						>
							<polygon points="5 3 19 12 5 21 5 3" />
						</svg>
					</div>
					<div className="skeleton-box h-2 w-2/6 block mt-0 mb-2"></div>
					<div className="skeleton-box h-2 w-4/6 block"></div>
				</div>
			</div>
		</>
	);
};

export default LoadingPlaceHolderVideosHomePage;
